<template>

  <div v-loading="isLoading" class="deform-gui">
    <div class="header">
      <el-button
        @click="runHandle"
      ><svg-icon icon-class="run" />运行</el-button>
      <div class="content">
        <application-public :isdir="1" :iscpu="1" :dirs="choiceDirData" :valid="isValid" @validChange="validChange" @changDirs="changDirs" @getData="getData" @formChange="formChange" />
        <div class="right">
          <div class="desc">
            <div class="title">应用介绍</div>
            <div class="text">The DEFORM® system is an engineering software that enables designers to analyze metal forming, heat treatment, machining and mechanical joining processes on the computer rather than the shop floor using trial and error.  Process simulation using DEFORM has been instrumental in cost, quality and delivery improvements at leading companies for two decades.</div>
          </div>
        </div>
      </div>
    </div>
    <!-- 选择目录或者文件组件 -->
    <choice-file :visible="dirFormVisible" :type="choiceType" @confirmDirs="confirmDirs" />
  </div>

</template>

<script>
import { ElMessage } from 'element-plus'
import {
  addJobs
} from '@/api/file'
import choiceFile from '../components/ChoiceFile.vue'
import applicationPublic from '../components/ApplicationPublic.vue'
import store from '@/store'

export default {
  components: { choiceFile, applicationPublic },
  data() {
    return {
      // 左侧公共组件
      valid: 0,
      isValid: 0,
      choiceDirData: '',
      isUseFortran: false,
      isLoading: true,
      currentRow: -1,
      loading: false,
      loadingDialog: false,
      dirFormVisible: false,
      filesData: [],
      tableData: [],
      data: [],
      path: '',
      queue: '',
      row: '',
      defaultProps: {
        children: 'childDir',
        label: 'name'
      },
      addForm: {
        jobName: '',
        dir: '',
        cpu: 1,
        gpu: 0
      },
      choiceType: 'dir',
      nodes: []
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.isLoading = false
    })
  },
  methods: {
    // 左侧子组件传值
    getData(isLoading, queue) {
      // this.isLoading = isLoading
      this.queue = queue
    },
    formChange(form) {
      this.addForm = form
    },
    validChange(val) {
      this.valid = val
    },
    // 选择目录 改变
    changDirs() {
      this.choiceType = 'dir'
      this.dirFormVisible = true
    },
    // 生成五位随机数
    randomString(e) {
      var t = 'abcdefghijklmnopqrstuvwxyz0123456789'
      var a = t.length
      var n = ''
      for (var i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a))
      return n
    },
    runHandle() {
      this.nodes = []
      this.nodes.push(this.addForm.nodes)
      this.nodes.push(this.addForm.nodes)
      this.isValid = 1
      const data = {
        'app': 'deform',
        'coupon_id': '',
        'script': '',
        'tasks': this.addForm.tasks_per_node * this.addForm.nodes,
        'tasks_per_node': this.addForm.tasks_per_node,
        'nodes': this.nodes,
        'partition': this.queue.queueName,
        'gpus': this.addForm.gpu,
        'jobType': 1,
        'name': this.addForm.jobName,
        'version': '',
        'current_working_directory': this.addForm.dir,
        'time_limit': this.addForm.lateTime === null ? null : parseInt(this.addForm.lateTime),
        'exclusive': this.addForm.exclusive ? 'user' : null

      }
      if (this.addForm.jobName !== '' && this.addForm.dir !== '' && this.valid === 1) {
        if (this.queue !== '') {
          addJobs(data).then((response) => {
            if (response.meta.status === 200) {
              ElMessage.success('运行成功')
              // this.$store.dispatch('core/close', 8889162036467440)
              const idObj = {
                id: 8606827790821716,
                linkId: 2299263294303102
              }
              this.$store.dispatch('core/close', idObj)
              this.$store.dispatch('core/openApp', 3145878294710521)
              this.row = ''
              this.queue = ''
              this.isValid = 3
            } else if (response.meta.status === 5005) {
              ElMessage.error('余额不足！')
            } else {
              ElMessage.error(response.meta.msg)
            }
          })
        } else {
          ElMessage.error('未选择队列信息，请点击选中队列信息！')
        }
      }
    },
    // 确定选择目录
    confirmDirs(data, dirFormVisible, type) {
      this.dirFormVisible = dirFormVisible
      if (type === 'dir') {
        this.addForm.dir = data
        this.choiceDirData = data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.deform-gui {
  padding: 20px;
  height: 100%;
  width: 100%;
  overflow: auto;
  ::v-deep .el-dialog{
    max-height: 600px;
    .el-dialog__body{
      max-height: 600px;
    }
  }
  .header {
    text-align: right;
    margin-bottom: 15px;
    ::v-deep .el-button {
      margin-right: 10px;
      background-color: #437aec;
      color: #fff;
      padding: 10px 16px;
      border-radius: 2px;
      min-height: 34px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
  }
  .content {
    text-align: left;
    display: flex;
    width: 100%;
    height: 100%;
    .left {
      margin-top: 20px;
      padding: 30px 0;
      background: rgba(245, 247, 250, 0.5);
      border: 1px solid #E8E8E8;
      min-width: 320px;
      width: 45%;
      position: relative;
      height: 100%;
      .el-form-item--small.el-form-item{
        margin-bottom: 40px;
      }
      .box{
        padding: 0 20px;
      }
      .middle{
        padding: 20px 0;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .border-style{
          border-bottom: 1px solid #D8D8D8;
          width: 100px;
        }
      }
      .title {
      position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
    }
      ::v-deep th.el-table__cell{
		background-color: #F5F5F5;
	}
    }
    .right {
      margin-top: 20px;
      padding: 0 10px;
      width: 100%;
      height: 100%;
      margin-left: 10px;
      .text{
        text-indent: 2em;
      }
       .desc{
         margin-bottom: 30px;
       }
      .desc,
      .app-info{
        position: relative;
        border: 1px solid #E8E8E8;
        padding: 30px 20px;
        background: rgba(245, 247, 250, 0.5);
      }
      .title{
        position: absolute;
      top: -15px;
      left: 20px;
      width: 80px;
height: 30px;
line-height: 30px;
text-align: center;
background: #FFFFFF;
border: 1px solid #E8E8E8;
      }
    }
  }
  ::v-deep .el-dialog{
  border-radius: 20px;
}
::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
}
</style>
<style>
/* 设置滚动条的样式 */
::-webkit-scrollbar {
  width: 12px;
}
/* 滚动槽 */
::-webkit-scrollbar-track {
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.3); */
  border-radius: 6px;
}
/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /* -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.5); */
}
::-webkit-scrollbar-thumb:window-inactive {
  background: #d8d8d8;
}
</style>
